<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="경영기획 업무요청">
            - 경영기획팀 업무관련 문의사항을 등록하시기 바랍니다.

        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <div class="mb-20">
                        <span class="float-right">＊필수 입력 항목입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 제목</th>
                                <td colspan="3">
                                    <input type="text" v-model.trim="add.title" class="w-100per">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 분류</th>
                                <td>
                                    <carrot-category @change="add.getPreset" ctype="managementplan" v-model="add.ctype" class="w-200px"></carrot-category>
                                </td>
                                <th><span class="txt-red">*</span> 희망완료일</th>
                                <td>
                                    <carrot-date-picker v-model="add.complete_date" class="w-110px"></carrot-date-picker>
                                </td>
                            </tr>
                            <tr>
                                <th>중요도</th>
                                <td colspan="3">
                                    <select v-model="add.priority" class="w-200px">
                                        <option value="1">낮음</option>
                                        <option value="2">보통</option>
                                        <option value="3">높음</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 내용</th>
                                <td colspan="3">
                                    <CarrotEditor etype="managementplan" v-model="add.contents" class="w-100per h-300px"></CarrotEditor>
                                </td>
                            </tr>
                            <tr>
                                <th>파일첨부</th>
                                <td colspan="3">
                                    <CarrotFileUploader ref="file1"></CarrotFileUploader>
                                    <CarrotFileUploader ref="file2" class="mt-5"></CarrotFileUploader>
                                    <CarrotFileUploader ref="file3" class="mt-5"></CarrotFileUploader>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button @click="add.doCancel" class="btn-default float-left mt-30">취소</button>
                    <button @click="add.doSubmit" class="btn-default float-right mt-30">등록</button>
                    <div class="clear"></div>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotCategory from '@/components/common/CarrotCategory.vue'
import CarrotEditor from '@/components/common/CarrotEditor.vue'
import Swal from 'sweetalert2'


export default {
    layout:"BusinessManagement",
    components: {
        CarrotDatePicker,
        CarrotCategory,
        CarrotEditor
    },
    setup() {
        const router = useRouter();
        const toast = useToast()

        const file1 = ref(null);
        const file2 = ref(null);
        const file3 = ref(null);
        const add = reactive({
            title : "",
            ctype : "",
            complete_date : "",
            priority      : 2,
            contents      : "",

            doCancel: () => {
                router.go(-1);
            },

            getPreset: () => {
                let params = {
                    ctype : add.ctype
                };
                axios.get("/rest/businessmgr/managementPlanPreset", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        add.contents = res.data.preset;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSubmit: () => {
                var formData = new FormData();
                formData.append("title", add.title);
                formData.append("ctype", add.ctype);
                formData.append("complete_date", add.complete_date);
                formData.append("priority"     , add.priority);
                formData.append("contents"     , add.contents);

                if(file1.value.file.filedata!==null) {
                    formData.append("nfile[]", file1.value.file.filedata);
                }
                if(file2.value.file.filedata!==null) {
                    formData.append("nfile[]", file2.value.file.filedata);
                }
                if(file3.value.file.filedata!==null) {
                    formData.append("nfile[]", file3.value.file.filedata);
                }

                if( !add.title || !add.ctype || !add.complete_date || !add.contents ){
                    console.log(add);
                    Swal.fire({
                        title : "이러닝",
                        text  : "필수 항목을 입력해주세요."
                    });
                    return;
                }

                axios.post('/rest/businessmgr/managementPlanAdd', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }).then((res)=>{
                    if( res.data.err == 0 ){
                        router.push({ 
                            name:"BusinessManagement-ManagementPlanView-idx",
                            params : { 
                                idx:res.data.idx 
                            }
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            }
        });

        onMounted(() => {
            // Mounted
        });

        return {add, file1, file2, file3};
    }
}
</script>

<style lang="scss" scoped>
</style>